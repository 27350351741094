<template>
    <div class="repaire">
        <rxNavBar title="维修列表" return-page="test" androidOrIOSFlag="true"></rxNavBar>
        <!--        <van-nav-bar fixed placeholder left-arrow title="维修列表" @click-left="returnLeft"></van-nav-bar>-->
        <div class="head">
            <!-- 搜索栏-->
            <div class="search">
                <div class="search-icon"></div>
                <input type="text" placeholder="小区名称/租客姓名/电话" v-model="inputValue" @keyup.enter="search">
            </div>
            <!--        下拉菜单 -->
            <div class="downMenuTitle" @click="isShow = !isShow" id="downList">
                <span> {{afterRentStatusMap[statusNum].text}}</span>
                <div class="triangleUp" v-if="!isShow"></div>
                <div class="triangleDown" v-if="isShow"></div>
            </div>
        </div>
        <div class="placeholderHead"></div>
        <div class="reviews">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :finished-text=" isEmptuyFlag ? '': '已经到底了'"
                          @load="afterRentContList">
                    <div class="review" v-for="(item,index) in afterRentList" :key="index">
                        <div :class="{'top-title-blue' : item.afterRentStatus=='0',
                                'top-title-orange' : item.afterRentStatus=='4',
                                'top-title-nine' : item.afterRentStatus=='9',
                                'top-title-ten' : item.afterRentStatus=='10',
                                'top-title-eight' : item.afterRentStatus=='8',
                                'top-title-eleven' : item.afterRentStatus=='11'  }">
                            {{item.afterRentStatus=='4'? '待验收' : item.afterRentStatus=='8' ? '已取消' :
                            item.afterRentStatus=='9' ?
                            '待处理' : item.afterRentStatus=='10' ? '派单中' : item.afterRentStatus=='11'?'已验收' :'待审批' }}
                        </div>
                        <div class="reviews-address">

                    <span class="position-span-right">
                        {{item.roomDetailedAddress ? item.roomDetailedAddress : '暂无'}}
                    </span>
                            <div class="addPlaceHolder"></div>
                            <div class="tel_IMg" @click="showMobile({row:item})">
                                <img class="tel" src="../../../assets/images/tel.png">
                            </div>
                        </div>

                        <div class="rule"></div>
                        <div class="reject" @click="finishedDetail(item)">
                            <div class="textTime">{{item.appointmentTime ? item.appointmentTime : '暂无'}}</div>
                            <div class="text">报修人：<span class="sp">{{item.applicationGlobaluserName ? item.applicationGlobaluserName : '暂无'}}</span>
                            </div>
                            <div class="text">维修类型：<span class="sp">{{item.repairType ? item.repairType : '暂无'}}</span>
                            </div>
                            <div class="text">维修描述：<span class="sp">{{item.afterRentDesprition ? item.afterRentDesprition : '暂无'}}</span>
                            </div>
                            <div class="text">驳回原因：<span class="sp">{{item.repairReason ? item.repairReason : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="rule"
                             v-if="item.afterRentStatus!='11'&&item.afterRentStatus!='8'&&item.afterRentStatus!='0'&&roleType=='1'"></div>
                        <div class="rule" v-if="item.afterRentStatus=='10'&&roleType=='0'"></div>
                        <!--==================================== 全部按钮=========================================-->
                        <!--                item.afterRentStatus==‘9’是待处理
                                            item.afterRentStatus==‘10’ 是派单中
                                            item.afterRentStatus==‘4’  是待验收
                                            item.afterRentStatus==‘11’ 是已验收
                                            item.afterRentStatus==‘8’   是已取消                  -->
                        <div class="btn"
                             v-if="item.afterRentStatus!='11'&&item.afterRentStatus!='8'&&item.afterRentStatus!='0'&&roleType=='1'">
                            <!--                    获取密码-->
                            <div class="getPwdDiv">
                                <!--                        获取密码按钮-->
                                <div class="getPwd" v-if="item.afterRentStatus=='10' && item.publicDeviceId"
                                     @click=" getPwd(item)"></div>
                            </div>
                            <!--                    完成按钮-->
                            <div class="finishedDiv" v-if="checkAuthList(authButtonsList,'completeRepairOrder')">
                                <!--                        完成-->
                                <button @click="finishedBtn(item)" class="finishedBtn"
                                        v-if="item.afterRentStatus=='10'">完成
                                </button>
                            </div>
                            <!--                  取消按钮-->
                            <div class="cancelBtnDiv">
                                <!--                        取消-->
                                <button @click="cancelClick(item)" class=' cancelBtn'
                                        v-if="(item.afterRentStatus=='9'||item.afterRentStatus=='10')&&checkAuthList(authButtonsList,'cancelRepairOrder')">
                                    取消
                                </button>
                            </div>
                            <!--                  派单 改派 按钮-->
                            <div class="repairBtnDiv">
                                <!--                        派单-->
                                <button v-if="(item.afterRentStatus=='9'&&checkAuthList(authButtonsList,'repairDistribute'))||(item.afterRentStatus=='10'&&checkAuthList(authButtonsList,'changeWorker'))"
                                        @click="repairClick(item)" class=' repairBtn'>
                                    {{item.afterRentStatus==9 ? '派单' : '改派' }}
                                </button>
                                <!--                        验收 -->
                                <button @click="acceptedClick(item)" class='repairBtn'
                                        v-if="item.afterRentStatus=='4'&&checkAuthList(authButtonsList,'checkRepair')">
                                    验收
                                </button>
                            </div>
                        </div>
                        <!--登录人是维修工人时，只显示更新进度和获取密码-->
                        <div class="btn" v-if="item.afterRentStatus=='10'&&roleType=='0'">
                            <!--                    获取密码-->
                            <div class="getPwdDiv">
                                <!--                        获取密码按钮-->
                                <div class="getPwd" v-if="item.afterRentStatus=='10' && item.publicDeviceId"
                                     @click=" getPwd(item)"></div>
                            </div>
                            <!--                    完成按钮-->
                            <div class="finishedDiv">
                                <!--                        完成-->
                                <button @click="finishedBtn(item)" class="otherFinishedBtn"
                                        v-if="item.afterRentStatus=='10'">完成
                                </button>
                            </div>
                        </div>
                    </div>
                </van-list>
                <!--空状态显示-->
                <common-empty v-if="isEmptuyFlag"></common-empty>
            </van-pull-refresh>
        </div>
        <!--        ===================================获取密码=========================================-->
        <van-overlay :show="pwdShow" @click="pwdShow = false">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <!--  获取密码的div-->
                    <div class="getPassword">
                        获取密码
                    </div>
                    <!--  门锁密码展示的DIV-->
                    <div class="LockPassWord" v-if="doorPassWord != undefined && doorPassWord != ''">
                        <p class="passwordText">公区密码</p>
                        <p class="password">{{doorPassWord}} </p>
                    </div>
                    <div class="passwordRule" v-if="doorPassWord != undefined && doorPassWord != '' && privatePwd != undefined && privatePwd != ''"></div>
                    <div class="LockPassWord" v-if="privatePwd != undefined && privatePwd != ''">
                        <p class="passwordText" >房门密码</p>
                        <p class="password">{{privatePwd}} </p>
                    </div>
                </div>
                <div class="closeImg" @click="closeMobileModel">
                </div>
            </div>
        </van-overlay>
        <!--    ======================================删除订单弹框=========================================-->
        <van-popup v-model="deleteShow" class="van-popup--bottom" position="bottom" :style="{ height: '45%' }">
            <img class="cancelOrdersImg" src="../../../assets/images/colon.png">
            <div class="cnacelOrdersText">确认</div>
            <div class="confirmCancelOrdersText">删除该保修单？</div>
            <van-button @click="deleteOrder" class="saveButton_Enable" style="margin-top: 50px">确认</van-button>
        </van-popup>
        <!--    ======================================取消订单弹框=========================================-->
        <van-popup v-model="cancelShow" class="van-popup--bottom" position="bottom" :style="{ height: '45%' }">
            <img class="cancelOrdersImg" src="../../../assets/images/colon.png">
            <div class="cnacelOrdersText">确认</div>
            <div class="confirmCancelOrdersText">取消该保修单？</div>
            <textarea placeholder="输入取消原因" v-model="cancellationReason"/>
            <van-button @click="cancelBtn" class="saveButton_Enable" style="margin-top: 50px">确认</van-button>
        </van-popup>
        <!--        =====================================派单的弹窗=====================================-->
        <van-popup class="van-popup--bottom" v-model="repairShow" position="bottom"
                   :style="{ height: this.afterRentStatus=='9' ? '65%' : '45%' }">
            <img class="sendOrdersTitleImg" src="../../../assets/images/colon.png">
            <div class="sendOrdersTitleText" v-if="this.afterRentStatus=='9'">维修派单</div>
            <div class="sendOrdersTitleText" v-else>维修改派</div>
            <!--        维护人-->
            <div id="repairId" class="part-inputpart-row">
                <span :class="staffName == ''? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">维修人</span>
                <span class="content-divide">|</span>
                <div class="downMenu" :value="staffName" @click="getMaintainer">
                    <div class="downMenuValue">{{staffName ? staffName : '请选择'}}</div>
                </div>
                <img class="part-inputpart-row-right"
                     :class=" showPicker ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <!--            费用承担方-->
            <div id="undertaker" class="part-inputpart-row" v-if="this.afterRentStatus=='9'">
                <span :class="bearer == ''? 'maker':'orange'">• </span>
                <span class="header">费用承担方</span>
                <span class="content-divide">|</span>
                <!--                <span class="part-inputpart-row-graytext" :class="isUndertakerSelect ? 'part-inputpart-row-normaltext':'' ">{{selectUndertaker}}</span>-->
                <div class="downMenu" :value="bearer" @click="getBearer">
                    <div class="downMenuValue">{{bearer ? bearer : '请选择'}}</div>
                </div>
                <img class="part-inputpart-row-right"
                     :class=" showPickerBearer ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <!--预计费用-->
            <div class="part-inputpart-row" v-if="this.afterRentStatus=='9'">
                <span :class="rent == ''? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-enablenon part-inputpart-row-header">预计费用</span>
                <span class="content-divide">|</span>
                <input type="number" v-model="rent" placeholder="输入金额">
                <span class="part-inputpart-row-right">元</span>
            </div>

            <!--                    上门时间-->
            <div id="checkInDatePanel" class="part-inputpart-row" @click="clickCheckInDateShow">
                <span :class="''==checkInDate ? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">上门时间</span>
                <span class="content-divide">|</span>
                <span class="date-graytext">{{checkInDate}}</span>
                <img class="part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
            </div>
            <van-button v-if="this.afterRentStatus=='9'" @click="confirm"
                        :class="!staffName || !bearer || !rent  || !checkInDate?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!staffName || !bearer || !rent  || !checkInDate||disabled">确定
            </van-button>
            <van-button v-if="this.afterRentStatus=='10'" @click="confirmChangeOrder"
                        :class="!staffName || !checkInDate?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!staffName || !checkInDate||disabled">确定
            </van-button>
        </van-popup>
        <!--        ====================上门时间的弹窗====================================================-->
        <van-popup v-model="isCheckInDateShow" position="bottom" class="selectTime">
            <div class="date-dropDownList">
                <van-datetime-picker v-model="currentDate" type="datetime" title="选择年月日" item-height="35px"
                                     @confirm="checkInDateConfirm" @cancel="checkInDateCancel"/>
            </div>
        </van-popup>
        <!--      ===========================  验收弹窗==================================================-->
        <van-popup class="van-popup--bottom" v-model="acceptedShow" position="bottom"
                   :style="{ height: '75%',overflow:'scroll'  }">
            <img style="float: left;margin: 20px 5px 0 20px;width: 15px;height: 15px;"
                 src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;margin-bottom: 0;font-size: 15px;font-weight: bold">验收</div>
            <div class="normalBlock">
                <span class="maker" :class="{'orange':(isPass||isReject)}">• </span>
                <span class="blockTitle">验收结果</span>
                <div class="rightText">
                    <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
                    <span class="content-divide"> | </span>
                    <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
                </div>
            </div>
            <!--            费用承担方-->
            <div class="part-inputpart-row" v-if="!isReject">
                <span :class="bearer == ''? 'maker':'orange'">• </span>
                <span class="header">费用承担方</span>
                <span class="content-divide">|</span>
                <!--                <span class="part-inputpart-row-graytext" :class="isUndertakerSelect ? 'part-inputpart-row-normaltext':'' ">{{selectUndertaker}}</span>-->
                <div class="downMenu" :value="bearer" @click="getBearer">
                    <div class="downMenuValue">{{bearer ? bearer : '请选择'}}</div>
                </div>
                <img class="part-inputpart-row-right"
                     :class=" showPickerBearer ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <!--最终费用-->
            <div class="part-inputpart-row" v-if="!isReject">
                <span :class=" lastFee == ''? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-enablenon part-inputpart-row-header">最终费用</span>
                <span class="content-divide">|</span>
                <input type="number" v-model="lastFee" placeholder="输入金额">
                <span class="part-inputpart-row-right">元</span>
            </div>
            <div class="normalBlock" v-if="!isReject">
                <span :class="value == 0?'maker':'orange'">• </span>
                <span class="blockTitle">施工负责人评价</span>

                <div class="star">
                    <van-rate v-model="value" :count="5"/>
                </div>
            </div>
            <div class="normalBlock" v-if="isReject">
                <span :class="rejectCauseValue == ''?'maker':'orange'">• </span>
                <span class="rejectCause">驳回原因 : </span>
                <!--                <input type="text" class="rejectCauseText" v-model="rejectCauseValue"disabled>-->
                <div class="downMenu" :value="rejectCauseValue" @click="showRefuseReason = true">
                    <div class="downMenuValue">{{rejectCauseValue ? rejectCauseValue : '请选择驳回原因'}}</div>
                </div>
                <img class="part-inputpart-row-right"
                     :class=" showRefuseReason ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
                <!--                <div class="arrows"></div>-->
            </div>
            <!--验收备注-->
            <div class="row2">
                <!--                通过显示-->
                <div class="acceptedRemake" v-if="!isReject">验收备注</div>
                <textarea v-if="!isReject" class="remake" v-model="remarks"/>
                <!--                驳回显示-->
                <div class="isRejectDiv" v-if="isReject">
                    <span :class="remarksReject == ''?'maker':'orange'">• </span>
                    <div class="acceptedRemakeTwo">描述</div>
                </div>
                <textarea v-if="isReject" placeholder="输入驳回原因" class="remake" v-model="remarksReject"/>

                <van-uploader class="uploadDiv" v-model="fileList" multiple :max-count="10" :after-read="afterReadTest" image-fit="contain">
                    <div class="upload">
                        <img src="../../../assets/images/grayAdd.png" class="upload_icon">
                        <div class="upload_font">上传图片</div>
                    </div>
                </van-uploader>
            </div>
            <van-button v-if="!isReject" @click="acceptedBtn" :class="isPass&&value>= 1 ?'saveEnable':'saveDisable'"
                        :disabled=" !isPass||!value>=1||disabled">确定
            </van-button>
            <van-button v-if="isReject" @click="acceptedBtn"
                        :class="isReject&& remarksReject&&rejectCauseValue?'saveEnable':'saveDisable'"
                        :disabled=" !isReject||!remarksReject||!rejectCauseValue||disabled">确定
            </van-button>
        </van-popup>
        <!--        ===========================维护人的弹窗===============================================-->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="sendOrders.repairPersonnelList" value-key="userName"
                        @cancel="showPicker = false" @confirm="changeStaffName"/>
        </van-popup>
        <!--        ===========================费用承担方的弹窗==============================================-->
        <van-popup v-model="showPickerBearer" position="bottom">
            <van-picker show-toolbar :columns="bearerList" value-key="dictionaryTitle"
                        @cancel="showPickerBearer = false" @confirm="selectUndertakerOption"/>
        </van-popup>
        <!--====================================拨打电话弹窗===================-->
        <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle"
                     :mobileShow="isMobileShow" @hideDialog="hideDialog">
        </dial-mobile>
        <!--        ==========================下拉框的弹窗==============================-->
        <van-popup v-model="isShow" position="bottom">
            <van-picker show-toolbar :columns="afterRentStatusMap" @cancel="isShow = false"
                        :default-index="afterRentStatusNum" value-key="text" @confirm="downMenu"/>
        </van-popup>
        <!--        ===============================驳回原因的弹窗=========================-->
        <van-popup v-model="showRefuseReason" position="bottom">
            <van-picker show-toolbar :columns="refuseReason" @cancel="showRefuseReason = false"
                        value-key="dictionaryTitle" @confirm="refuseReasonFun"/>
        </van-popup>
        <loadingFlag v-show="loadingFlag"></loadingFlag>
    </div>

</template>

<script>
    import {
        picker,
        Button,
        DatetimePicker,
        DropdownItem,
        DropdownMenu,
        NavBar,
        Popup,
        Search,
        Uploader,
        Rate,
        Overlay,
        List,
        PullRefresh
    } from "vant";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import loadingFlag from "./LoadingFlag";
    import {getStaffId, globaluserId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import {
        afterRentContList, base64Upload,
        cancelRentRepairOrder,
        queryBaseData, queryRepairCheckDetail, queryRepairDetailCard, queryRepairPersonnelList,
        repairDel, repairFinishDetail, saveChangeWorker, savecheckRepair,
        saveRepairDistribute, userGetPassword
    } from "../../../getData/getData";
    import {
        getStaffFuntionModelList
    } from "../../../getData/getData";
    import {checkAuthList} from "../../../libs/rongxunUtil";

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Rate.name]: Rate,
            dialMobile,
            [Overlay.name]: Overlay,
            rxNavBar,
            [picker.name]: picker,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            loadingFlag
        },
        name: "repairToBeAccepted",
        data() {
            return {
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                lastFee: '',
                loadingFlag: false,
                //下拉刷新
                isLoading: false,
                //空状态显示
                isEmptuyFlag: false,
                //权限
                authButtonsList: [],
                //下拉菜单
                isShow: false,
                afterRentStatusMap: [
                    {text: '全部', value: ''},
                    {text: '待处理', value: 9},
                    {text: '派单中', value: 10},
                    {text: '待验收', value: 4},
                    {text: '已验收', value: 11},
                    {text: '已取消', value: 8}
                ],
                statusNum: 1,
                afterRentStatusNum: '',
                //搜索
                inputValue: '',
                //上拉加载
                pageCount: '',
                page: 1,
                loading: false,
                finished: false,
                //=======================删除订单============================================
                //删除订单弹窗控制
                deleteShow: false,
                //========================派单弹窗============================================
                disabled: false,
                //维修ID
                repair_id: '',
                //预计费用
                rent: '',
                //上门时间
                checkInDate: '',
                toTime: '',
                //承担方列表
                bearerList: [],
                //承担方
                bearer: '',
                //承担方弹窗控制
                showPickerBearer: false,
                //维护人名字
                staffName: '',
                //维修人ID
                getStaffId: '',
                //维护人列表
                sendOrders: {},
                //维护人的弹窗控制
                showPicker: false,
                //派单弹窗控制
                repairShow: false,
                //=======================改派=====================
                //改派的弹窗控制
                changeOrder: false,
                //状态
                afterRentStatus: '',
                //========================取消订单===========================================
                //取消订单弹窗控制
                cancelShow: false,
                //取消订单文本域
                cancellationReason: '',
                //========================验收============================================
                //验收的弹窗控制
                acceptedShow: false,
                //接收的图片列表
                fileList: [],
                //验收评价
                remarks: '',
                //验收初始化返回值
                finishDetail: {},
                //验收详情初始数据
                RepairCheckList: {},
                // 维修详情初始数据
                RepairDetailList: {},
                //评价分
                value: 0,
                //驳回描述
                remarksReject: '',
                //驳回原因
                refuseReason: [],
                //驳回原因
                rejectCauseValue: '',
                showRefuseReason: false,
                //==============================================
                //初始化列表返回数据的LIST
                afterRentList: [],
                //获取密码============================================
                //获取密码弹窗控制
                pwdShow: false,
                //房源ID
                roomId: '',
                setHouse_id:'',
                roomHouse_id:'',
                equipment_id:'',
                //联系申请人
                dialogTitle: '联系申请人',
                doorPassWord: '暂无',
                privatePwd: '暂无',
                //拨打电话弹窗 start
                isMobileShow: false,
                temporaryOwnerMobile: '',
                temporaryOwnerName: '',
                //拨打电话弹窗 end
                show: false,
                //上门时间
                isCheckInDateShow: false,
                //选择日期
                currentDate: new Date(),
                //验收的btn控制
                isPass: false,
                isReject: false,
                bearerId: '',
            }

        },
        mounted() {

            //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
            if (this.$route.query.roleType) {
                this.roleType = this.$route.query.roleType;
                localStorage.setItem('currentLabel', JSON.stringify(2));
            } else {
                //列表
                this.initDb()
                this.getStaffFuntionModelList()
            }
            if (JSON.parse(localStorage.getItem('currentLabel'))) {
                this.statusNum = Number(JSON.parse(localStorage.getItem('currentLabel')))
            }
            //接收工作台传递的参数,如果存在，保存至本地
            else if (this.$route.query.currentLabel) {
                switch (Number(this.$route.query.currentLabel)) {
                    //待处理
                    case 2 :
                        localStorage.setItem('currentLabel', JSON.stringify(1))
                        break;
                    //维修中
                    case 5 :
                        localStorage.setItem('currentLabel', JSON.stringify(2))
                        break;
                    //待回访
                    case 6 :
                        localStorage.setItem('currentLabel', JSON.stringify(4))
                        break;
                    default :
                        localStorage.setItem('currentLabel', JSON.stringify(1))
                }
                this.statusNum = Number(JSON.parse(localStorage.getItem('currentLabel')))
            }
            //搜索缓存
            if (JSON.parse(localStorage.getItem('inputValue'))) {
                this.inputValue = JSON.parse(localStorage.getItem('inputValue'));
            }
        },
        beforeDestroy() {

        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if (from.name == null) {
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        methods: {
            onRefresh() {
                this.isLoading = false;
                this.afterRentList = [];
                this.page = 1;
                this.loading = false;
                this.finished = false;
                this.loading = true;
                this.afterRentContList()
                responseUtil.alertMsg(this, "刷新成功")
            },
            getStaffFuntionModelList() {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'afterRentRepair_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,
            //下拉框
            downMenu(value, values) {
                localStorage.setItem('currentLabel', JSON.stringify(values))
                this.afterRentStatusNum = value.value
                this.statusNum = values
                this.isShow = false;
                this.upList()
            },
            //上拉加载方法
            upList() {
                this.afterRentList = [];
                this.page = 1;
                this.loading = false;
                this.finished = false;
                this.loading = true;
                this.afterRentContList()
            },
            //搜索
            search() {
                if (this.inputValue != '') {
                    localStorage.setItem('inputValue', JSON.stringify(this.inputValue))
                } else {
                    localStorage.removeItem('inputValue')
                }
                this.upList()
            },
            //维修订单删除接口======================================================
            repairDel() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.reid = that.repair_id
                repairDel(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '删除订单成功')
                    })
                })
            },
            //维修订单删除按钮点击事件
            repairDelBtn(item) {
                this.deleteShow = true
                this.repair_id = item.repair_id
            },
            //维修订单确认删除按钮
            deleteOrder() {
                this.deleteShow = false
                this.repairDel()
                this.afterRentContList()
            },
            //维修取消订单接口========================================================
            cancelRentRepairOrder() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.reid = that.repair_id
                initData.cancellationReason = that.cancellationReason
                cancelRentRepairOrder(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '订单取消成功')
                        that.upList()
                    })
                })
            },
            //取消订单按钮点击弹窗
            cancelClick(item) {
                this.cancellationReason = ''
                this.cancelShow = true
                this.repair_id = item.repair_id
            },
            //取消订单确认按钮
            cancelBtn() {
                this.cancelRentRepairOrder()
                this.disabled = true
                this.cancelShow = false
                setTimeout(() => {
                    this.disabled = false
                }, 2000)


            },
            //维修派单保存接口========================================================
            saveDistribute() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.repairPersonnel = that.getStaffId
                initData.toTime = new Date(that.checkInDate)
                initData.costBear = that.bearerId
                initData.estimatedCost = that.rent
                initData.reid = that.repair_id
                saveRepairDistribute(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '派单成功')
                        that.upList()
                    })
                })
            },
            //派单保存跳转
            //派单和改派按钮点击弹窗
            repairClick(item) {
                this.repair_id = item.repair_id
                this.afterRentStatus = item.afterRentStatus
                this.repairShow = true
                // if(item.afterRentStatus == '9'){
                //
                // }
                this.repairFinishDetail()
                this.queryRepairPersonnelList()
                this.staffName = ''
                this.bearer = ''
                this.rent = ''
                this.checkInDate = ''
            },
            //确定派单按钮
            confirm() {
                this.disabled = true
                this.repairShow = false
                this.saveDistribute()
                setTimeout(() => {
                    this.disabled = false
                }, 2000)
            },
            //承担方弹窗显示
            getBearer() {
                this.showPickerBearer = true
            },
            //费用承担方的选择方法
            selectUndertakerOption(value) {
                this.bearer = value.dictionaryTitle
                this.bearerId = value.dictionaryValue
                this.showPickerBearer = false
            },
            //驳回原因
            refuseReasonFun(value) {
                this.rejectCauseValue = value.dictionaryTitle
                this.Reason_id = value.id
                this.showRefuseReason = false
            },
            //选择维护人的方法
            changeStaffName(value) {
                this.staffName = value.userName;
                this.getStaffId = value.id
                this.showPicker = false;
            },
            //  获取维护人的方法
            getMaintainer() {
                this.showPicker = true
                // this.queryRepairPersonnelList()
            },
            queryRepairPersonnelList() {
                let that = this
                let initPersion = {}
                initPersion.user_id = globaluserId()
                initPersion.reid = that.repair_id
                queryRepairPersonnelList(initPersion).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.sendOrders = response.data.data
                    })
                })
            },
            //改派保存接口==============================================
            //确定改派按钮
            confirmChangeOrder() {
                this.disabled = true
                this.repairShow = false
                this.saveChangeWorker()
                setTimeout(() => {
                    this.disabled = false
                }, 2000)

            },
            //改派保存接口
            saveChangeWorker() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.reid = that.repair_id
                initData.toTime = new Date(that.checkInDate)
                if (!that.getStaffId) {
                    for (let i = 0; i < that.sendOrders.repairPersonnelList.length; i++) {
                        if (that.staffName == that.sendOrders.repairPersonnelList[i].userName) {
                            that.getStaffId = that.sendOrders.repairPersonnelList[i].id
                        }
                    }
                }
                initData.repairPersonnel = that.getStaffId
                saveChangeWorker(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '改派成功')
                        that.upList()
                    })
                })
            },
            //列表初始化==================================================
            afterRentContList: function () {
                let that = this
                let initData = {}
                initData.afterRentType = '1'
                //Del
                if (that.roleType == '1') {
                    initData.staff_id = getStaffId()
                } else if (that.roleType == '0') {
                    initData.staff_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
                }
                initData.currentPage = that.page
                initData.numberPage = '5'
                initData.roleType = that.roleType
                initData.searchName = that.inputValue
                if (that.statusNum) {
                    initData.afterRentStatus = that.afterRentStatusMap[that.statusNum].value
                } else {
                    initData.afterRentStatus = that.afterRentStatusNum
                }
                afterRentContList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data)
                        if (response.data.data.data.length == 0) {
                            that.isEmptuyFlag = true
                        } else {
                            that.isEmptuyFlag = false
                        }
                        that.pageCount = response.data.data.pageCount
                        if (that.pageCount != 0) {
                            for (let i = 0; i < response.data.data.data.length; i++) {
                                that.afterRentList.push(response.data.data.data[i])
                            }
                        }
                        // 加载状态结束
                        that.page++
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.afterRentList.length >= that.pageCount) {
                            that.finished = true;
                        }
                    })
                })
            },
            //初始化字典=====================================================
            initDb: function () {
                let that = this
                let initData = {}
                initData.dbName = ['refuseReason']
                initData.fdName = ['COSTBEARMAP', 'AFTERRENTSTATUSMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.refuseReason = response.data.data.refuseReason
                        that.bearerList = response.data.data.COSTBEARMAP
                    })
                })
            },

            //验收=========================================================
            //维修详情
            queryRepairDetailCard() {
                let that = this
                let initData = {}
                initData.reid = that.repair_id
                queryRepairDetailCard(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.RepairDetailList = response.data.data
                    })
                })
            },
            //验收详情接口
            queryRepairCheckDetail() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.repair_id
                queryRepairCheckDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.RepairCheckList = response.data.data.item
                        console.log(that.RepairCheckList)
                        for (let i = 0; i < that.bearerList.length; i++) {
                            if (that.RepairCheckList.costBear == that.bearerList[i].dictionaryValue) {
                                that.$set(that, 'bearer', that.bearerList[i].dictionaryTitle)
                                that.$set(that, 'bearerId', that.bearerList[i].dictionaryValue)
                            }
                        }
                        that.lastFee = that.RepairCheckList.finishFee
                    })
                })
            },
            //维修完成详情
            repairFinishDetail() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.repair_id
                repairFinishDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.finishDetail = response.data.data
                        if (that.afterRentStatus == '10' && that.repairShow == true) {
                            that.checkInDate = that.replaceTime(response.data.data.data.lastDispatchTime)
                            that.staffName = response.data.data.data.userName

                        }
                    })
                })
            },
            //时间分隔符替换（ios时间间隔不允许是‘-’，此方法将其替换为‘/’）
            replaceTime(date){
                var timeOne = date.replace('-','/')
                var timeTwo = timeOne.replace('-','/')
                return timeTwo
            },
            acceptedClick(item) {
                this.acceptedShow = true
                this.repair_id = item.repair_id
                //清除历史记录
                this.isPass = false
                this.isReject = false
                this.bearer = ''
                this.value = 0
                this.remarks = ''
                this.remarksReject = ''
                this.rejectCauseValue = ''
                this.fileList = []
                //验收详情
                this.repairFinishDetail()
                this.queryRepairCheckDetail()
                this.queryRepairDetailCard()
            },
            acceptedBtn() {
                this.acceptedShow = false
                this.disabled = true
                this.savecheckRepair()
                setTimeout(() => {
                    this.disabled = false
                }, 2000)
            },
            // 通过、驳回按钮
            choosePass() {
                this.isPass = true;
                this.isReject = false;
            },
            chooseReject() {
                this.isReject = true;
                this.isPass = false;
            },

            //图片多选上传
            afterReadTest(file){
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                debugger
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            file.path = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },

            //验收保存接口
            savecheckRepair() {
                let that = this
                let initData = {}
                //用户ID
                initData.user_id = getStaffId()
                //维修ID
                initData.reid = that.repair_id
                //通过驳回选项
                if (that.isPass) {
                    initData.radioSelect = '通过'
                } else {
                    initData.radioSelect = '驳回'
                    //驳回原因
                    initData.rejectionReason = that.Reason_id
                    //描述
                    initData.describe = that.remarksReject
                }
                //备注
                initData.remarks = that.remarks
                //评价
                initData.pingjia = that.value
                //维修类型
                initData.dictionaryTitle = that.RepairDetailList.detail.dictionaryTitle
                //图片List
                initData.picList = that.fileList
                //费用承担方
                initData.costBear = that.bearerId
                //最终费用
                initData.finalCost = that.lastFee
                savecheckRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, '验收成功')
                        that.upList()
                        // ====清空记录=====
                        that.RepairCheckList = {}
                        that.fileList = []
                        that.value = 0
                        that.remarksReject = ''
                        that.remarks = ''
                        that.isPass = false
                        that.isReject = false
                    })
                })
            },
//====================================完成=====================================
            finishedBtn(item) {
                this.$router.push({
                    name: 'RepairDetails',
                    query: {
                        id: item.id,
                        repair_id: item.repair_id,
                        roleType: this.roleType   //是否是装修负责人  0 是    1 否
                    }
                })
            },
//============================查看详情信息=================================
            finishedDetail(item) {
                this.$router.push({
                    name: 'viewDetails',
                    query: {
                        id: item.id,
                        repair_id: item.repair_id
                    }
                })
            },
            viewDetail() {
                this.$router.push({
                    name: 'acceptanceCheckTheDetails',
                    query: {}
                })
            },
            //拨打电话
            showMobile(e) {
                let that = this;
                that.temporaryOwnerMobile = e.row.applicationGlobaluserMoblile;
                that.temporaryOwnerName = e.row.applicationGlobaluserName
                that.isMobileShow = !that.isMobileShow;
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            //日期选择组件 点击确认键后执行方法
            endPeriodConfirm(value) {
                var date = new Date(value)
                this.contractInfo.endPeriod = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            },
            // 事件选择事件
            clickCheckInDateShow() {
                this.isCheckInDateShow = !this.isCheckInDateShow;
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value) {
                this.toTime = value
                var date = new Date(value)
                this.checkInDate = this.formatDate(date)
                this.isCheckInDateShow = false
            },
            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel() {
                this.isCheckInDateShow = false
            },

            //获取密码
            getPwd(item) {
                // this.doorPassWord = "暂无"
                this.setHouse_id = item.setHouse_id
                this.roomHouse_id = item.roomHouse_id
                this.equipment_id = item.publicDeviceId
                this.userGetPassword()
            },
            userGetPassword() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.setHouse_id = that.setHouse_id
                initData.roomHouse_id = that.roomHouse_id
                initData.equipment_id = that.equipment_id
                initData.type = "3"
                userGetPassword(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        that.doorPassWord = response.data.data.publicPwd  // 公区密码
                        that.privatePwd = response.data.data.privatePwd   //间密码
                    })
                    that.pwdShow = true

                })
            },
            //关闭密码弹窗
            closeMobileModel() {
                this.pwdShow = false
            },
        }
    }
</script>

<style lang="less" scoped>

    .head {
        position: fixed;
        top: 40px;
        width: 100%;
        height: 100px;
        background-color: #f7f7f7;
        z-index: 100;
    }

    //下拉菜单
    .downMenuTitle {
        width: 20%;
        display: flex;
        margin-top: 20px;
        margin-left: 15px;
        background-color: #f7f7f7;
        height: 25px;

        span {
            color: red;
            font-size: 13px;
        }

        .triangleUp, .triangleDown {
            width: 0;
            height: 0;
            margin-left: 10px;
            border-width: 3px;
            border-style: solid;
        }

        .triangleUp {
            margin-top: 7px;
            border-color: black transparent transparent transparent;
        }

        .triangleDown {
            margin-top: 3px;
            border-color: transparent transparent black transparent;
        }
    }

    .placeholderHead {
        width: 100%;
        height: 90px;
    }

    .repaire {
        .van-overlay {
            background-color: rgba(80, 80, 80, .7);;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    /*    获取密码DIV*/
    .getPassword {
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right, #FFC274, #FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }

    /*    门锁密码*/
    .LockPassWord {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    .passwordRule {
        width: 70%;
        margin: 20px auto;
        text-align: center;
        border: 0.5px solid #eeeeee;
    }

    /*传入的密码值*/
    .password {
        color: #ff8247;
        font-size: 20px;
        margin-top: 30px;
        font-family: SimSun;
    }
    .passwordText {
        color: black;
        font-size: 16px;
        margin-top: 20px;
        font-family: SimSun;
    }

    /*关闭按钮*/
    .closeImg {
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size: 18px 18px;
    }

    /*弹窗样式*/
    .van-popup--bottom {
        background-color: #f8f8f8;
        //派单
        overflow: hidden;

        .sendOrdersTitleImg {
            float: left;
            margin: 20px 5px 0 15px;
            width: 15px;
            height: 15px;
        }

        //派单
        .sendOrdersTitleText {
            margin-top: 18px;
            margin-bottom: 20px;
            font-size: 15px;
            font-weight: bold;
        }

        //取消订单
        .cancelOrdersImg {
            float: left;
            margin: 20px 5px 0 15px;
            width: 15px;
            height: 15px;
        }

        //取消订单
        .cnacelOrdersText {
            margin-top: 18px;
            font-size: 15px;
            font-weight: bold
        }

        //取消订单
        .confirmCancelOrdersText {
            margin-left: 35px;
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: bold
        }
    }

    /*验收备注Div*/
    .row2 {
        float: left;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        /*height: 240px;*/
        border-radius: 8px;
        padding-bottom: 15px;
        margin: 15px 5px 15px 15px;
    }

    /*验收备注*/
    .acceptedRemake {
        margin-left: 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
    }

    .acceptedRemakeTwo {
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
    }

    .isRejectDiv {
        display: flex;
        height: 30px;
        align-items: center;
    }

    .date-graytext {
        color: #ff5809;
        width: 100%;
        font-size: 14px;
        margin-top: 1px;
    }

    .date-dropDownList {
        /*margin-left: 15px;*/
        /*width: 92%;*/
        /*margin-right: 10px;*/
        /*position: absolute;*/
        /*z-index: 99;*/
        /*margin-top: 5px;*/
    }

    .blockTitle {
        float: left;
        margin-right: 25px;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
    }

    .rejectCause {
        /*float: left;*/
        font-size: 14px;
        font-weight: bold;
        width: 100px;
    }

    /*.rejectCauseText{*/
    /*    width: 60%;*/
    /*    background-color: #ffffff;*/
    /*}*/
    .rightText {
        margin-left: auto;
        font-size: 14px;
        color: #999999;
    }

    .star {
        margin-left: 8px;
        margin-top: 8px;
        font-size: 14px;
        color: #999999;
    }

    .choosedStyle {
        color: #ff3c00;
        font-weight: bold;
    }

    .passText {
        flex: 1;
        padding-right: 7px;
    }

    .rejectText {
        padding-left: 7px;
        padding-right: 33px;
    }

    .normalBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        width: 92%;
        height: 50px;
        border-radius: 8px;
        /*margin: 10px 15px 0 10px;*/
        margin: 10px auto;
    }

    /*选择名称的div*/
    .part-inputpart-row {
        width: 345px;
        float: left;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        height: 50px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }

    /*备注输入框*/
    .remake {
        color: gray;
        width: 88%;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 0 auto;
        background-color: #f8f8f8;
        font-size: 12px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        overflow: hidden;
    }

    /*上传图片*/
    .uploadDiv {
        display: flex;
        margin: 10px 0 0 10px;
        /*height: 70px;*/
        width: 345px;
        /*background: url("../../../assets/images/grayAdd.png")30px 30px;*/
    }

    .upload {
        border-radius: 10px;
        height: 90px;
        width: 90px;
        background-color: #f8f8f8;
        margin-left: 10px;
        margin-top: 10px;
        float: left;
    }

    .upload_icon {
        height: 35px;
        width: 35px;
        margin-left: 27px;
        margin-top: 20px;
    }

    .upload_font {
        height: 25px;
        font-size: 12px;
        line-height: 2px;
        margin-left: 20px;
        margin-top: 5px;
        color: gray;
    }

    .part-inputpart-row input {
        background-color: white;
        width: 100%;
    }

    .part-inputpart-row-header {
        font-weight: bold;
        font-size: 14px;
        width: 140px;
    }

    .header {
        font-weight: bold;
        font-size: 14px;
        width: 140px;

    }

    .part-inputpart-row-graytext {
        color: black;
        width: 100%;
        font-size: 14px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 10px;
        font-size: 14px;
        color: #D8D8D8;
    }

    .part-inputpart-dropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 50px;
    }

    .part-inputpart-row-normaltext {
        color: #ff5809;
    }

    .complaintTypedropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    .complaintTypedropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 110px;
    }

    /*圆点样式---灰色*/
    .maker {
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 0 10px;
    }

    /*圆点样式---橘色*/
    .orange {
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 0 10px;
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
        margin-right: 10px;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin: 0 5px 0 5px;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 30px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveDisable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        /*margin:50px 50px 15px 15px;*/
        margin: 0 auto;
        display: block;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveEnable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        /*margin:50px 50px 15px 15px;*/
        margin: 0 auto;
        display: block;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }

    textarea {
        resize: none;
        color: gray;
        font-size: 12px;
        width: 89%;
        display: block;
        height: 80px;
        background: #f0f0f0;
        margin: 0 auto;
        border-radius: 10px;
        padding: 10px 0 0 10px;
        border: none;
    }

    .navbar {
        z-index: 10;
        background-color: #f8f8f8;
    }

    .search {
        margin: 20px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }

    .search-icon {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
    }

    input {
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    .icon {
        float: left;
        width: 150px;
        height: 30px;
        margin-top: 5px;
    }

    .bottom_iconDiv {
        float: left;
        width: 155px;
        height: 30px;
    }

    .pwd_IMG {
        float: left;
        width: 30px;
        height: 25px;
        margin-left: 15px;
        margin-top: 3px;
    }

    .reviews {
        /*margin-top: 15px;*/
        /*width: 345px;*/
        /*height: 260px;*/
    }

    .review {
        width: 92%;
        margin: 0 auto 15px;
        background-color: white;
        border-radius: 8px;
    }

    // 最后的按钮
    .btn {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        //删除按钮
        .getPwdDiv {
            width: 19%;
            /*border: 1px solid red;*/
            /*display: flex;*/
            /*align-items: center;*/

            .getPwd {
                /*border: 1px solid red;*/
                height: 25px;
                width: 30px;
                background: url("../../../assets/images/RepairPending-getPwd.png") no-repeat center center;
                background-size: 100% 100%;
                margin-left: 8px;
                margin-bottom: 8px;
                /*border: none;*/
            }
        }

        /*完成按钮*/

        .finishedDiv {
            width: 27%;

            .finishedBtn {
                width: 80px;
                height: 30px;
                font-size: 14px;
                border: none;
                border-radius: 8px;
                background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
                text-align: center;
                color: white;
            }

            .otherFinishedBtn {
                width: 80px;
                height: 30px;
                font-size: 14px;
                border: none;
                border-radius: 8px;
                background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
                text-align: center;
                color: white;
                margin-left: 5rem;
            }
        }

        //取消按钮
        .cancelBtnDiv {
            width: 27%;

            .cancelBtn {
                height: 30px;
                width: 80px;
                font-size: 14px;
                border: 1px solid transparent;
                border-radius: 8px;
                background-image: linear-gradient(white, white),
                linear-gradient(to right, #ffc274, #ff5D3B);
                background-clip: padding-box, border-box;
                background-origin: border-box;
                color: #ff5809;
                text-align: center;
            }
        }

        //派单按钮
        .repairBtnDiv {
            width: 27%;

            .repairBtn {
                height: 30px;
                width: 80px;
                font-size: 14px;
                /*line-height: 31px;*/
                border: none;
                border-radius: 8px;
                background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
                text-align: center;
                color: white;
            }
        }
    }

    .top-title-orange, .top-title-eleven, .top-title-nine, .top-title-ten, .top-title-eight, .top-title-blue {
        width: 38px;
        height: 15px;
        font-size: 10px;
        text-align: center;
        line-height: 16px;
        text-align: center;
        border-radius: 8px 0;
        color: white;
    }

    .top-title-orange {
        background: linear-gradient(to right, #E02020, #FF431B);
    }

    .top-title-eight {
        background: linear-gradient(to right, #999999, #cccccc);
    }

    .top-title-nine {
        background: linear-gradient(to right, #FF5D3B, #FFC274);
    }

    .top-title-ten {
        background: linear-gradient(to right, #66CB94, #28D3B0);
    }

    .top-title-eleven {
        background: linear-gradient(to right, #990066, #ff66ff);
    }

    .top-title-blue {
        background: linear-gradient(to right, #7FCAFF, #3091FF);
    }

    .getPwd {
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }

    //租后列表中的地址
    .reviews-address {
        height: 42px;
        width: 92%;
        margin: 0 auto;
        font-weight: bold;
        display: -webkit-box;
        display: flex;
        align-items: center;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .position-span-right {
            color: black;
            font-size: 15px;
            width: 90%;
        }

        .addPlaceHolder {
            width: 10px;
            height: auto;
        }

        .tel_IMg {
            width: 20px;
            height: 20px;
            /*margin-right: 20px;*/
            line-height: 20px;
        }

        .tel {
            width: 19px;
            height: 18px;
        }
    }

    /* 分割线*/
    .rule {
        border: 0.5px solid #e6e6e6;
        width: 100%;
    }

    /*报修详情*/
    .reject {
        /*height: 120px;*/
        /*border: 1px solid red;*/
        width: 92%;
        margin: 10px auto 0;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .sp {
        color: #9a9a9a;
        font-size: 13px;
        font-weight: normal;
    }

    .text {
        color: black;
        font-size: 14px;
        font-weight: bold;
        overflow: hidden;
    }

    .textTime {
        color: #ff5809;
        font-size: 14px;
        font-weight: bold;
    }

    .trashCanShow {
        display: block;
    }

    .downMenu {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;

        .downMenuValue {
            width: 95%;
            font-size: 14px;
            color: #999;
        }

        .arrows {
            width: 0;
            height: 0;
            border: 3.5px solid;
            border-color: black transparent transparent transparent;
            margin-top: 5px;
        }
    }

    .selectTime {
        width: 100%;
        height: 6rem;
    }

</style>
